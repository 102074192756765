<template lang="pug">
    .container.w-96
        add-user
</template>

<script>
//Components
import AddUser from '@/components/views/users/add-user'

export default {
    name: "AddUserPage",
    components: {
        'add-user': AddUser,
    },
}
</script>